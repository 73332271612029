<!-- @format -->

<template>
	<div>
		<LayoutDefaultNew>
			<SubNavbar page="Dispatch"></SubNavbar>
			<div>
				<div v-if="isPopupVisible">
					<VPopup :closePopup="onClosePopup">
						<template #header>
							<div>
								{{ formName }}
							</div>
						</template>
						<template #body>
							<div>
								<ViewSelectedOrder />
							</div>
						</template>
						<template #footer>
							<div>
								{{ formName }}
							</div>
						</template>
					</VPopup>
				</div>
				<section class="hero is-danger margin-bottom-20">
					<div class="hero-body">
						<p class="title">Create dispatch from orders</p>
						<p class="subtitle">Please select the orders to dispatch</p>
					</div>
				</section>
				<section class="margin-bottom-20 margin-left-20">
					<p>
						<span class="text-bold">OurCompany:</span>
						&nbsp;{{ ourCompany.toUpperCase() }}
					</p>
					<p>
						<span class="text-bold">Customer:</span>
						<span class="text-bold text-blue">
							&nbsp;{{ customer.customer_id }} &nbsp; - &nbsp;
							{{ customer.customer_title }} &nbsp; - &nbsp;
							{{ customer.customer_nickname }}
						</span>
					</p>
					<p>
						<span class="text-bold">Dispatch Address:</span>&nbsp;
						{{ dispatchAddress.address_nickname }}
					</p>
					<p>{{ dispatchAddress.line_1 }}&nbsp;{{ dispatchAddress.line_2 }}</p>
					<p>
						{{ dispatchAddress.post_code }}&nbsp;<span class="text-bold">{{
							dispatchAddress.city
						}}</span>
					</p>
					<p>
						<span class="text-green text-bold"
							>{{ dispatchAddress.state }}
						</span>
						&nbsp;
						<span class="text-bold text-green">
							{{ dispatchAddress.country }}
						</span>
					</p>
					<p>
						<span class="text-bold"> Route id: </span> &nbsp;
						<span class="text-bold text-red">
							{{ dispatchAddress.route_id }}
						</span>
					</p>
				</section>
				<section>
					<DispatchHeader />
				</section>
			</div>
			<div class="text-bold margin-top-20">
				Customer's orders waiting to dispatch
			</div>
			<div class="margin-bottom-30 margin-top-10">
				<vue-good-table
					id="orders"
					:columns="selectOrderColumns"
					:rows="rows"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					max-height="700px"
					:fixed-header="true"
					@on-row-dblclick="onRowDoubleClick"
					@on-cell-click="onCellClick"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'select'">
							<b-button
								class="is-small is-success"
								:disabled="isSelectDisabled"
							>
								Select
							</b-button>
						</span>
						<span v-else-if="props.column.field === 'order_total'">
							<p class="text-bold text-purple text-align-right">
								{{ numeral(props.row.order_total / 100).format('0,0.00') }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'preview'">
							<b-button class="is-small is-info">Preview</b-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
			</div>
			<div>
				<p class="text-bold">Selected orders to dispatch</p>
				<div class="margin-top-10 margin-bottom-30">
					<vue-good-table
						id="selected_orders"
						:columns="selectedOrdersColumns"
						:rows="rowsSelectedOrders"
						styleClass="vgt-table striped bordered"
						theme="black-rhino"
						max-height="700px"
						:fixed-header="true"
						@on-row-dblclick="onRowDoubleClickSelectedOrders"
						@on-cell-click="onCellClickSelectedOrders"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 10,
							position: 'bottom',
							perPageDropdown: [5, 10, 15, 20],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'remove'">
								<b-button
									class="is-small is-success"
									:disabled="isRemoveDisabled"
									>Remove</b-button
								>
							</span>
							<span v-else-if="props.column.field === 'order_total'">
								<p class="text-bold text-purple text-align-right">
									{{ numeral(props.row.order_total / 100).format('0,0.00') }}
								</p>
							</span>
							<span v-else-if="props.column.field === 'preview'">
								<b-button class="is-small is-info"> Preview </b-button>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
				</div>
			</div>
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					margin-top: -30px;
				"
				class="margin-bottom-30"
			>
				<div>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(182, 90, 92); color: white"
						:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
						@click="
							preventButtonDoubleClick(
								onSaveAndView,
								1000,
								disableObject,
								'onLeaveAsDraft',
							)
						"
					>
						Leave as draft
					</b-button>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(61, 196, 134); color: white"
						:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
						@click="
							preventButtonDoubleClick(
								onSaveAndView,
								1000,
								disableObject,
								'onSave',
							)
						"
					>
						Save
					</b-button>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(52, 136, 206); color: white"
						:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
						@click="
							preventButtonDoubleClick(
								onSaveAndView,
								1000,
								disableObject,
								'onSaveAndView',
							)
						"
					>
						Save & View
					</b-button>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						type="is-danger"
						outlined
						@click="onCancel"
					>
						Cancel
					</b-button>
				</div>
			</div>
			<div v-if="!!isModalVisible">
				<ModalDispatchFrame :key="keyModal" processType="add" />
			</div>
		</LayoutDefaultNew>
	</div>
</template>
<script>
import SubNavbar from '../../../components/_shared/sub-navbar/SubNavbar.vue'
import useDispatchStore from '@/_srcv2/pages/dispatch/_shared/useDispatchStore.js'
import DispatchHeader from './DispatchHeader.vue'
import { computed, ref, reactive, watchEffect } from '@vue/composition-api'
// * -----------------------------------------------------------------------------
import GetNewDispatchNumberMutation from '@/_srcv2/pages/dispatch/graphql/GetNewDispatchNumberMutation.gql'
import AddNewDispatchMutation from '@/_srcv2/pages/dispatch/graphql/AddNewDispatchMutation.gql'
import AddOrderToDispatchMutation from '@/_srcv2/pages/dispatch/graphql/AddOrderToDispatchMutation.gql'
import SetDispatchLockedMutation from '@/_srcv2/pages/dispatch/graphql/SetDispatchLockedMutation.gql'
import SetDispatchUnlockedMutation from '@/_srcv2/pages/dispatch/graphql/SetDispatchUnlocked.mutation.graphql'
import MutateStockCreditAmountMutation from '@/_srcv2/pages/dispatch/graphql/mutateStockCreditAmount.mutation.graphql'
import { useMutation } from '@vue/apollo-composable'
// * ------------------------------------------------------------------------------
import Router from '@/router'
import dateformat from 'dateformat'
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import VPopup from '@/_srcv2/pages/admin/edit-stock-prices/sub-components/VPopup.vue'
import ViewSelectedOrder from '@/_srcv2/pages/dispatch/add-dispatch/ViewSelectedOrder.vue'
import useSelectedOrderViewState from '@/_srcv2/pages/dispatch/_shared/useSelectedOrderViewState.js'
import numeral from 'numeral'

export default {
	name: 'AddDispatch',
	components: {
		SubNavbar,
		DispatchHeader,
		ModalDispatchFrame,
		VPopup,
		ViewSelectedOrder,
	},
	setup() {
		const {
			ourCompany,
			customer,
			dispatchAddress,
			// ! ---------------------------------------------------
			orders,
			// ! -----------------------------------------------------
			selectedOrders,
			selectOrderColumns,
			selectedOrdersColumns,
			// ! ------------------------------------------------------
			dispatchExchangeRate,
			dispatchExchangeUnit,
			selectedDispatchDate,
			dispatchAddressId,
			invoiceAddressId,
			dispatchType,
			selectedDispatchLanguage,
			// ! ------------------------------------------------------
		} = useDispatchStore()
		const arrayRemove = (arr, value) => {
			console.log('value', value)
			return arr.filter(function (item) {
				return item.order_number !== value
			})
		}
		// ! ----------------------------------------------------------------------
		const { selectedOrderViewState } = useSelectedOrderViewState()
		// ! ------------------------------------------------------------------------------
		// * Eligible orders table
		const rows = computed(() => orders.value)
		const isSelectDisabled = ref(false)
		const isSelectThrottled = ref(false)
		const saveOrderHeaders = (params) => {
			selectedOrderViewState.rows =
				params.row.order_heders_goods_transactions_rel
			selectedOrderViewState.selectedOrderCustomer =
				customer.value.customer_id +
				' - ' +
				customer.value.customer_title +
				' - ' +
				customer.value.customer_nickname
			selectedOrderViewState.selectedOrderDate = params.row.order_date
			selectedOrderViewState.selectedOrderNumber = params.row.order_number
			console.log('******** params.rows', params.row)
		}
		const onSelect = (params) => {
			if (params.column.field === 'select') {
				selectedOrders.value.push(params.row)
				orders.value = arrayRemove(orders.value, params.row.order_number)
				isSelectDisabled.value = true
				isSelectThrottled.value = true
				setTimeout(() => {
					isSelectDisabled.value = false
					isSelectThrottled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveOrderHeaders(params)
				isPopupVisible.value = true
			}
		}
		const onCellClick = (params) => {
			if (isSelectThrottled.value === false) {
				onSelect(params)
			} else {
				console.log('wait')
			}
		}
		const onRowDoubleClick = (params) => {
			onSelect(params)
		}
		// todo ------------------------------------------------------------------------------
		// * Selected Orders Table
		const rowsSelectedOrders = computed(() => selectedOrders.value)
		const isRemoveDisabled = ref(false)
		const isRemoveThrottled = ref(false)
		const onRemove = (params) => {
			if (params.column.field === 'remove') {
				orders.value.push(params.row)
				selectedOrders.value = arrayRemove(
					selectedOrders.value,
					params.row.order_number,
				)
				isRemoveDisabled.value = true
				isRemoveThrottled.value = true
				setTimeout(() => {
					isRemoveDisabled.value = false
					isRemoveThrottled.value = false
				}, 1000)
			} else if (params.column.field === 'preview') {
				saveOrderHeaders(params)
				isPopupVisible.value = true
			}
		}
		const onCellClickSelectedOrders = (params) => {
			if (isRemoveThrottled.value === false) {
				onRemove(params)
			} else {
				console.log('wait')
			}
		}
		const onRowDoubleClickSelectedOrders = (params) => {
			onRemove(params)
		}
		// ***********************************************************************
		// ! Add new dispatch Mutations -----------------------------------------
		// * GetNewDispatchNumberMutation
		const {
			mutate: mutateGetNewDispatchNumber,
			// onDone: onDoneGetNewInvoiceNumber,
		} = useMutation(GetNewDispatchNumberMutation, () => ({
			variables: {
				company_id: ourCompany.value,
			},
		}))
		// ! Create new dispatch header ---------------------------------------------
		// * AddNewDispatchMutation
		const mutationVariablesAddNewDispatch = reactive({
			company_id: ourCompany.value,
			customer_id: customer.value.customer_id,
			dispatch_address_id: dispatchAddressId.value,
			dispatch_date: computed(() =>
				dateformat(selectedDispatchDate.value, 'yyyy-mm-dd'),
			),
			dispatch_exchange_rate: dispatchExchangeRate.value,
			dispatch_exchange_unit: dispatchExchangeUnit.value,
			dispatch_language: computed(() => selectedDispatchLanguage.value),
			dispatch_lock: true,
			dispatch_number: '',
			dispatch_type: dispatchType.value,
			invoice_address_id: invoiceAddressId.value,
		})
		watchEffect(() =>
			console.log(
				'mutationVariablesAddNewDispatch',
				mutationVariablesAddNewDispatch,
			),
		)
		const {
			mutate: mutateAddNewDispatchMutation,
			// onDone: onDoneAddNewInvoiceMutation,
		} = useMutation(AddNewDispatchMutation, () => ({
			variables: { input: mutationVariablesAddNewDispatch },
		}))
		// ! -----------------------------------------------------------------------
		// * AddOrderToDispatchMutation
		const mutationVariablesOrderToDispatch = reactive({
			dispatch_number: '',
			order_number: '',
		})
		const {
			mutate: mutateAddOrderToDispatch,
			// onDone: onDoneAddDispatchToInvoice,
		} = useMutation(AddOrderToDispatchMutation, () => ({
			variables: mutationVariablesOrderToDispatch,
		}))
		// ! ---------------------------------------------------------------------
		// * SetDispatchLockedMutation
		const mutationVariablesSetDispatchLocked = reactive({
			company_id: ourCompany.value,
			dispatch_number: '',
		})
		const {
			mutate: mutateSetDispatchLocked,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(SetDispatchLockedMutation, () => ({
			variables: mutationVariablesSetDispatchLocked,
		}))
		// * SetDispatchUnlockedMutation
		const {
			mutate: mutateSetDispatchUnlocked,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(SetDispatchUnlockedMutation, () => ({
			variables: mutationVariablesSetDispatchLocked,
		}))
		// ! ---------------------------------------------------------------------
		// * MutateStockCreditAmountMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: ourCompany.value,
			stock_id: '',
			credit_amount: 0,
		})
		const {
			mutate: mutateStockCreditAmount,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(MutateStockCreditAmountMutation, () => ({
			variables: mutationVariablesMutateStockCreditAmountMutation,
		}))
		// * ---------------------------------------------------------------------
		// ! Functions
		const setNewDispatchNumber = (result) => {
			console.log('setNewDispatchNumber is fired result', result)
			return new Promise((resolve, reject) => {
				if (result !== 0) {
					const initial = result.data.update_companies.returning[0].letter_code
					mutationVariablesOrderToDispatch.dispatch_number = `${initial} ${result.data.update_companies.returning[0].dispatch_number}`
					mutationVariablesSetDispatchLocked.dispatch_number = `${initial} ${result.data.update_companies.returning[0].dispatch_number}`
					mutationVariablesAddNewDispatch.dispatch_number = `${initial} ${result.data.update_companies.returning[0].dispatch_number}`
					// ! ----------------------------------------------------------------------------------------------------------------------
					// *** Process Report Values
					reportDocumentType.value = 'dispatch'
					reportDocumentNumber.value = `${initial} ${result.data.update_companies.returning[0].dispatch_number}`
					reportDocumentOurCompany.value =
						result.data.update_companies.returning[0].company_nickname
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = `${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`
					reportDocumentDate.value =
						mutationVariablesAddNewDispatch.dispatch_date
					reportDocumentStatus.value = 'Dispatch is locked & not invoiced'
					resolve(mutationVariablesOrderToDispatch.dispatch_number)
				} else {
					const reason = new Error('New Dispatch Number could not be created')
					reject(reason)
				}
			})
		}
		// ! ---------------------------------------------------------------------
		const insertDispatchHeaders = (data) => {
			console.log('insertDispatchHeaders is fired')
			return new Promise((resolve, reject) => {
				console.log(
					'insertDispatchHeaders is fired mutationVariablesAddNewDispatch',
					mutationVariablesAddNewDispatch,
				)
				let result = ''
				if (data !== null || undefined) {
					mutateAddNewDispatchMutation()
					resolve(result)
				} else {
					const reason = new Error(
						'Dispatch headers could not be added to database',
					)
					reject(reason)
				}
			})
		}
		// ! ---------------------------------------------------------------------
		const selectedOrderNumbers = computed(() =>
			selectedOrders.value.map((item) => item.order_number),
		)
		// ! ---------------------------------------------------------------------
		const updateGoodsTransactions = (data) => {
			console.log('updateGoodsTransactions is fired')
			return new Promise((resolve, reject) => {
				if (data !== null || undefined) {
					console.log('****selectedOrderNumbers', selectedOrderNumbers)
					for (let item in selectedOrderNumbers.value) {
						mutationVariablesOrderToDispatch.order_number =
							selectedOrderNumbers.value[item]
						console.log(
							'selectedOrderNumbers: ',
							selectedOrderNumbers.value[item],
						)
						console.log(
							'6666 mutationVariablesOrderToDispatch',
							mutationVariablesOrderToDispatch,
						)
						mutateAddOrderToDispatch()
					}
					// mutateAddDispatchToInvoice()
					resolve('ok')
				} else {
					const reason = new Error(
						'Dispatches could not be added to sale-invoice',
					)
					reject(reason)
				}
			})
		}
		// ! ---------------------------------------------------------------------
		// * trigger stock credit amount
		const lines = ref([])
		const getStockCreditAmountArray = () => {
			let ordersArray = rowsSelectedOrders.value.map((item) => {
				return item.order_heders_goods_transactions_rel
			})
			console.log('is array ordersArray', Array.isArray(ordersArray))
			console.log('ordersArray', ordersArray)
			const ordersArrayLength = ordersArray.length
			for (let i = ordersArrayLength; i > 0; i--) {
				console.log('linesArray:', ordersArray[i - 1])
				let order = ordersArray[i - 1]
				const orderLength = order.length
				for (let i = orderLength; i > 0; i--) {
					console.log('line:', order[i - 1])
					lines.value.push(order[i - 1])
				}
			}
			console.log('lines.value', lines.value)
			console.log('is array', Array.isArray(lines.value))
			const linesLength = lines.value.length
			for (let i = linesLength; i > 0; i--) {
				console.log('line:', lines.value[i - 1])
				let item = lines.value[i - 1]
				mutationVariablesMutateStockCreditAmountMutation.company_id =
					ourCompany.value
				mutationVariablesMutateStockCreditAmountMutation.stock_id =
					item.stock_id
				mutationVariablesMutateStockCreditAmountMutation.credit_amount =
					item.dispatch_amount
				mutateStockCreditAmount()
			}
		}
		// ! ---------------------------------------------------------------------
		const createTheDispatch = (method) => {
			mutateGetNewDispatchNumber()
				.then((result) => setNewDispatchNumber(result))
				.then((data) => {
					insertDispatchHeaders(data)
					console.log('**** insertDispatchHeaders data', data)
				})
				.then((data) => updateGoodsTransactions(data))
				.then(() => {
					switch (method) {
						case 'onSaveAndView':
							mutateSetDispatchLocked()
								.then(() => getStockCreditAmountArray())
								.then(() => {
									getTheDispatch()
								})
							break
						case 'onSave':
							mutateSetDispatchLocked()
								.then(() => getStockCreditAmountArray())
								.then(() => {
									Router.push({ name: 'ViewOrdersToDispatch' })
								})
							break
						case 'onLeaveAsDraft':
							mutateSetDispatchUnlocked()
								.then(() => getStockCreditAmountArray())
								.then(() => {
									Router.push({ name: 'ViewOrdersToDispatch' })
								})
							break
						default:
							Router.push({ name: 'ViewOrdersToDispatch' })
					}
				})
		}
		// ! ---------------------------------------------------------------------
		// * Cancel / Save / View the Dispatch
		const onSaveAndView = (method) => {
			createTheDispatch(method)
		}
		// ! ---------------------------------------------------------------------
		const disableButtons = computed(() => selectedOrders.value.length === 0)
		const onCancel = () => {
			if (
				confirm(
					'Are you sure to cancel the dispatch \n To cancel dispatch press "ok" \n To continue to edit dispatch press "cancel"',
				)
			) {
				Router.push({ name: 'ViewOrdersToDispatch' })
				alert('You have canceled the dispatch ')
			}
		}
		const preventButtonDoubleClick = (fn, delay, obj, method) => {
			fn(method)
			obj.isSaveAndViewDisabled = true
			setTimeout(() => (obj.isSaveAndViewDisabled = false), delay)
		}
		const disableObject = reactive({
			isSaveAndViewDisabled: false,
		})
		// ! ---------------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber, // ***
			reportDocumentOurCompany, // ***
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
			isUnlockButtonVisible,
		} = useProcessReportStore()
		// ! ------------------------------------------------------------
		const keyModal = ref(1)
		const getTheDispatch = () => {
			isUnlockButtonVisible.value = false
			isModalVisible.value = true
			keyModal.value += 1
		} // ? Open modal
		// todo ------------------------------------------------------------
		const isPopupVisible = ref(false)
		const onClosePopup = () => {
			isPopupVisible.value = false
		}
		// todo -----------------------------------------------------------
		const formName = ref('Preview Order Details')
		return {
			formName,
			// ! ------------------------------------------------
			ourCompany,
			customer,
			dispatchAddress,
			// ! ------------------------------------------------
			//  * Eligible Orders Table
			onCellClick,
			onRowDoubleClick,
			orders,
			rows,
			selectOrderColumns,
			isSelectDisabled,
			// ! --------------------------------------------------
			// * Selected Orders Table
			rowsSelectedOrders,
			selectedOrdersColumns,
			onCellClickSelectedOrders,
			onRowDoubleClickSelectedOrders,
			isRemoveDisabled,
			// ! --------------------------------------------------
			onCancel,
			onSaveAndView,
			disableButtons,
			preventButtonDoubleClick,
			disableObject,
			isModalVisible,
			keyModal,
			isPopupVisible,
			onClosePopup,
			numeral,
			// * delete
			// createTheDispatch,
		}
	},
}
</script>
