<!-- @format -->

<template>
	<div>
		<b-field class="margin-top-20" label="Select dispatch date">
			<b-datepicker
				v-model="dispatchDate"
				:show-week-number="showWeekNumber"
				:locale="locale"
				placeholder="Click to select..."
				icon="calendar-today"
				trap-focus
				:first-day-of-week="firstDayOfTheWeek"
			>
			</b-datepicker>
		</b-field>
		<b-dropdown v-model="isSwedish" aria-role="list">
			<template v-if="isSwedish" #trigger>
				<b-button
					label="Swedish"
					type="is-info"
					icon-left="home"
					icon-right="menu-down"
				/>
			</template>

			<template v-else #trigger>
				<b-button
					label="English"
					type="is-primary"
					icon-left="earth"
					icon-right="menu-down"
				/>
			</template>

			<b-dropdown-item :value="true" aria-role="listitem">
				<div class="media">
					<b-icon class="media-left" icon="home"></b-icon>
					<div class="media-content">
						<h3>Swedish</h3>
						<small>Dispatch Language will be Swedish</small>
					</div>
				</div>
			</b-dropdown-item>

			<b-dropdown-item :value="false" aria-role="listitem">
				<div class="media">
					<b-icon class="media-left" icon="earth"></b-icon>
					<div class="media-content">
						<h3>English</h3>
						<small>Dispatch Language will be English</small>
					</div>
				</div>
			</b-dropdown-item>
		</b-dropdown>
	</div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
// import addDays from 'add-days'
// import useSalesInvoiceState from '@/_srcv2/pages/sale-invoice/_shared/useSalesInvoiceState.js'
import useDispatchStore from '@/_srcv2/pages/dispatch/_shared/useDispatchStore.js'
export default {
	name: 'DispatchHeader',
	setup() {
		const showWeekNumber = ref(false)
		const firstDayOfTheWeek = ref(1)
		const locale = 'sv-SE'
		const dispatchDate = ref(new Date())
		const isSwedish = ref(true)
		const fetchDates = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					dispatchDate.value = new Date()
					isSwedish.value = true
					resolve(true)
				} else {
					const reason = new Error('Date and Language could not be set')
					reject(reason)
				}
			})
		}
		const setDates = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					selectedDispatchDate.value = dispatchDate.value
					selectedDispatchLanguage.value = 'se'
					resolve('Ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			fetchDates(true)
				.then((data) => {
					setDates(data)
				})
				.then(() => {
					console.log('Dates are set')
					console.log('dispatch date', selectedDispatchDate.value)
					console.log(
						'selectedDispatchLanguage',
						selectedDispatchLanguage.value,
					)
				})
		})
		const {
			// stateDispatch,
			selectedDispatchDate,
			selectedDispatchLanguage,
		} = useDispatchStore()
		watch(isSwedish, (newValue) => {
			console.log(newValue)
			if (isSwedish.value) {
				selectedDispatchLanguage.value = 'se'
			} else {
				selectedDispatchLanguage.value = 'en'
				console.log(
					'selectedDispatchLanguage.value',
					selectedDispatchLanguage.value,
				)
			}
		})
		return {
			firstDayOfTheWeek,
			showWeekNumber,
			dispatchDate,
			isSwedish,
			locale,
		}
	},
}
</script>

<style scoped></style>
