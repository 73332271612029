/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const selectedOrderViewState = reactive({
	selectedOrderNumber: '',
	selectedOrderDate: '',
	selectedOrderCustomer: '',
	rows: [],
	// ! ------------------------------------------------------------------------
	columns: [
		{
			label: 'Item',
			field: 'line_info',
			width: '410px',
		},
		{
			label: 'Price',
			field: 'invoice_price',
			width: '135px',
			html: true,
		},
		{
			label: 'Amount',
			field: 'dispatch_amount',
			width: '135px',
			html: true,
		},
		{
			label: 'VAT',
			field: 'vat_credit',
			width: '135px',
			type: 'number',
			html: true,
		},
		{
			label: 'Line total',
			field: 'line_price_total_credit',
			width: '135px',
			type: 'number',
			html: true,
		},
	],
})
export default function useSelectedOrderViewState() {
	return {
		...toRefs(selectedOrderViewState),
		selectedOrderViewState,
	}
}
